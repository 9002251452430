import { gql, useMutation } from "@apollo/client";
import React from "react";
import { AttendanceModel } from "../../models/attendance.model";
import { useDialog } from "../../services/dialog.context";
import { AttendeeDropinFragment } from "./use-list-attendees";


const DropinMutation = gql`
mutation do_dropin($object: do_dropin_input!, $attendance_id: bigint!) {
    do_dropin(object: $object) {
        attendance_id
        attendee {
            ${AttendeeDropinFragment}
        }
    }
}
`;

type Props = {
    attendance: AttendanceModel|undefined,
}

export const useDropin = ({
    attendance,
}: Props) => {
    const [_doDropin] = useMutation(DropinMutation)
    const dialog = useDialog();
    const [doing_dropin, setDoingDropin] = React.useState<{ [id: number]: boolean }>({})

    const doDropin = async (attendee_id: number, undo?: boolean) => {
        try {
            if (doing_dropin[attendee_id]) {
                return;
            }
            setDoingDropin(d => ({
                ...d,
                [attendee_id]: true,
            }))
            const { data } = await _doDropin({
                variables: {
                    attendance_id: attendance?.id,
                    object: {
                        attendance_id: attendance?.id,
                        attendee_id,
                        undo,
                    }
                }
            })
            if (data?.do_dropin) {
                const attendee = data.do_dropin.attendee;
                const toast_id = `${attendee_id}${attendance?.state}${undo}`;
                // if (!toast.isActive(toast_id)) {
                //     toast.show({
                //         id: toast_id,
                //         description: undo ? `Dropin removed` : `${attendee.name} ${attendance?.state === AttendanceState.checkin ? 'checked in' : 'checked out'}`
                //     })
                // }
            }
        } catch (e) {
            dialog.showError(e);
        } finally {
            setDoingDropin(d => ({
                ...d,
                [attendee_id]: false,
            }))
        }
    }

    return {
        doing_dropin,
        doDropin,
    }
}