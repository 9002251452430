import React from 'react'
import { AttendanceModel, AttendanceStateOptions } from '../models/attendance.model'

export type AttendanceItemProps = {
    item: AttendanceModel;
    onSelect: () => void;
}

function AttendanceItem({ item, onSelect }: AttendanceItemProps) {
    return <button onClick={onSelect} className='block w-full'>
        <div className='py-4 px-2 my-2 rounded-lg flex items-center bg-slate-200 hover:bg-slate-300'>
            <p className='flex-1 font-bold'>
                {item.name} - {AttendanceStateOptions.find(o => o.id === item.state)?.label}
            </p>
            <i className='fa fa-chevron-forward' />
        </div>
    </button>
}

export default AttendanceItem