import { Currency } from "./currency";

export type EventModel = {
    id: number;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
    title: string;
    feature_images?: string[];
    content?: any;
    starts_at: string;
    ends_at: string;
    timezone?: string;
    is_live?: boolean;
    ticket_order?: number[];
    organization: {
        id: number;
        currency: Currency;
    }
    attendee_tally?: {
        id: number;
        total_attendees?: number;
    };
    invoice_item_tally?: {
        id: number;
        total_quantity?: number;
        total_amount?: number;
    };
    attendees: {
        id: number;
        name: string;
        created_at: string;
    }[];
}

export const EventFragment = `
    id
    created_at
    updated_at
    deleted_at
    title
    feature_images
    content
    starts_at
    ends_at
    timezone
    is_live
    ticket_order
    organization {
        id
        currency
    }
    attendee_tally {
        id
        total_attendees
    }
    attendees(limit: 4, order_by: {id: desc}) {
        id
        name
        created_at
    }
    invoice_item_tally {
        id
        total_quantity
        total_amount
    }
`;
