import { createContext, FC, useContext, useState } from "react";
import { parseServerError } from "../apollo/parse-server-error";
import ModalDialog, { DialogProps, ModalDialogProps } from "../components/ModalDialog";

export type ProvideDialogProps = {
    children?: React.ReactNode;
}

const DialogContext = createContext<{
    showDialog: (_: DialogProps) => void;
    showError: (error: any) => void;
}>({} as any);

export const ProvideDialog = ({
    children,
}: ProvideDialogProps) => {
    const [open, setOpen] = useState<DialogProps | false>(false)

    const showDialog = (dialog: DialogProps) => {
        setOpen(dialog);
    }

    const showError = (error: any) => {
        const { message } = parseServerError(error);
        setOpen({
            title: 'Error',
            message: message || 'Unknown error',
            buttons: [{
                label: 'Okay',
            }],
        })
    }

    return <DialogContext.Provider value={{
        showDialog,
        showError,
    }}>
        {open ? <ModalDialog
            dialog={open}
            onClose={() => setOpen(false)}
        /> : null}
        {children}
    </DialogContext.Provider>
}


export const useDialog = () => {
    return useContext(DialogContext);
}