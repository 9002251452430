import React, { useState } from 'react';
import EventHeader from '../components/EventHeader';
import { AttendanceModel } from '../models/attendance.model';
import { useEvent } from '../services/use-event';
import AttendanceScreen from './attendance/AttendanceScreen';
import AttendanceListScreen from './AttendanceListScreen';

type Props = {
}

function Main({ }: Props) {
    const [attendance, setAttendance] = useState<AttendanceModel | null>();
    const { event } = useEvent();

    return (
        <div className="container">
            {attendance ? <AttendanceScreen
                attendance={attendance}
                onDismiss={() => setAttendance(null)} /> : <>
                <EventHeader event={event} />
                <AttendanceListScreen
                    event_id={event.id}
                    onSelectAttendance={setAttendance} />
            </>}
        </div>
    )
}


export default Main