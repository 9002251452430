
import React from "react";
import { useList } from "../apollo/networking";
import AttendanceItem from "../components/AttendanceItem";
import Button from "../components/Button";
import Loading from "../components/Loading";
import { AttendanceFragment, AttendanceModel } from '../models/attendance.model';
import { EventModel } from "../models/event.model";

type Props = {
    event_id: number;
    onSelectAttendance: (att: AttendanceModel) => void;
}

const AttendanceListScreen = ({
    onSelectAttendance,
    event_id,
}: Props) => {
    const [editor, setEditor] = React.useState<{
        action: 'edit' | 'create';
        item?: AttendanceModel;
    } | undefined>();
    const {
        items,
        loading,
        refetch,
    } = useList<AttendanceModel>({
        entity_name: 'attendance',
        fragment: AttendanceFragment,
        query_inner: `
            where: {event_id: {_eq: $event_id}},
            order_by: {id: asc}
        `,
        clause_outer: `$event_id: bigint!`,
        variables: {
            event_id,
        }
    })

    const onCreate = () => {
        setEditor({
            action: 'create',
        })
    }
    const onFinish = (_: AttendanceModel, is_new?: boolean) => {
        if (is_new) {
            refetch();
        }
        setEditor(undefined);
    }

    return <div>
        <div className='flex justify-between items-center border-b-2 py-2 mb-4'>
            <p className='text-md flex-1'>Select Attendance to start</p>
            <Button onClick={onCreate} small label='+ Create' />
        </div>

        {/* {editor ? <AttendanceEditorDialog
            item={editor.item}
            action={editor.action}
            onClose={() => setEditor(undefined)}
            onFinish={onFinish}
            event_id={event.id}
        /> : null} */}
        {items.length === 0 && !loading ? <p>No attendance found</p> : null}
        {loading ? <Loading /> : null}
        {items.map(item => <AttendanceItem key={item.id}
            item={item}
            onSelect={() => onSelectAttendance(item)} />)}
    </div>
}

export default AttendanceListScreen