import React, { ReactElement, useMemo } from 'react'

export interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    label: string;
    fullWidth?: boolean;
    small?: boolean;
    loading?: boolean;
    iconLeft?: ReactElement
    iconRight?: ReactElement
}

function Button({
    label,
    onClick,
    type = 'button',
    fullWidth,
    small,
    loading,
    disabled,
    iconLeft,
    iconRight,
    ...props
}: ButtonProps) {

    const _classname = useMemo(() => [
        "flex items-center justify-center text-sm font-medium rounded-lg",
        disabled ? 'bg-gray-200 cursor-not-allowed' : 'text-white bg-orange-600 active:bg-orange-500 hover:bg-orange-700',
        props.className,
        !fullWidth ? 'w-fit' : 'w-full',
        small ? 'py-2 px-3' : 'px-5 py-3',
    ].join(' '), [disabled, fullWidth, props.className])

    return (
        <button
            {...props}
            type={type}
            onClick={onClick}
            className={_classname}>
            <span className='flex items-center justify-center '>
                {loading ? <svg
                    className='w-4 h-4 mr-2 animate-spin'
                    fill="currentColor"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z" />
                </svg>
                    : iconLeft 
                    ? <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        {iconLeft}</svg> : null}
                    {label} {iconRight 
            ? <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                {iconRight}</svg> : null}
            </span>
        </button>
    )
}

export default Button