import { gql, useLazyQuery } from "@apollo/client";
import { AttendeeDropinFragment } from "./use-list-attendees";

type Props = {
    event_id: number;
    attendance_id: number;
}

const QueryAttendeeSearch = gql`
    query attendees($query: String, $event_id: bigint!, $attendance_id: bigint!) {
        search_attendee(args: {
            search: $query
        }, where: {
            ticket: { event_id: {_eq: $event_id}}
        }) {
            ${AttendeeDropinFragment}
        }
    }
`;

export const useSearch = ({
    event_id,
    attendance_id,
}: Props) => {
    const [
        _doSearch,
        search_result
    ] = useLazyQuery(QueryAttendeeSearch)

    const doSearch = (query: string) => {
        _doSearch({
            variables: {
                query,
                event_id,
                attendance_id,
            }
        })
    }

    const data = search_result.data;

    return {
        items: data?.search_attendee || [],
        loading: search_result.loading || false,
        error: search_result.error,
        doSearch
    }
}