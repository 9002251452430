import React, { useEffect } from 'react';
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import { AttendanceModel, AttendanceState } from "../../models/attendance.model";
import { useDebounce } from '../../services/use-debounce';
import { useEvent } from '../../services/use-event';
import { AttendeeItem } from "./attendee-item";
import { useUpdateAttendance } from "./update-attendance";
import { useDropin } from "./use-dropin";
import { AttendeeDropinModel, useListAttendees } from "./use-list-attendees";
import { useSearch } from "./use-search";

type Props = {
    attendance: AttendanceModel;
    onDismiss: () => void;
}


const AttendanceScreen = ({
    attendance: att,
    onDismiss,
}: Props) => {
    const { event } = useEvent();
    const [is_searching, setIsSearching] = React.useState(false);
    const [text, setText] = React.useState('');
    const debounce_text = useDebounce(text, 500);

    useEffect(() => {
        onSearch(debounce_text)
    }, [debounce_text])
    const {
        attendance,
        updateState
    } = useUpdateAttendance(att.id);
    const {
        items,
        refetch,
        loading,
        fetchMore,
        has_more,
    } = useListAttendees(att.event_id, att.id);
    const {
        doSearch,
        ...search_result
    } = useSearch({
        event_id: att.event_id,
        attendance_id: att.id,
    })
    const {
        doDropin,
        doing_dropin,
    } = useDropin({
        attendance,
    })


    const dismiss = () => {
        if (is_searching) {
            setText('');
            setIsSearching(false);
            return;
        }
        onDismiss();
    }

    const onScan = (attendee_id: number) => {
        doDropin(attendee_id);
        // setShowScanner(false);
    }

    const onSearch = async (query: string) => {
        if (!query) {
            setIsSearching(false);
            return;
        }
        setIsSearching(true);
        doSearch(query);
    }

    const handleLoadMore = () => {
        if (has_more && items.length > 0) {
            fetchMore({
                event_id: att.event_id,
                attendance_id: att.id,
                cursor: items[items.length - 1].id,
            });
        }
    }

    if (!attendance) {
        return <Loading />
    }

    return <div>
        <div className='py-4'>
            <button className='p-1 rounded-md bg-slate-300 text-sm border-2'
                onClick={onDismiss}>Change Attendance</button>
            <h2 className='text-xl font-bold'>{event.title}</h2>
            <p className='text-md text-gray-7000'>{attendance.name}</p>
            <div className='text-center'>
                <p className='text-4xl font-bold'>{
                    attendance.checkin_tally?.total_checkins || 0}/{event.attendee_tally?.total_attendees || 0}</p>
                <p className='uppercase text-sm text-gray-800'>Check-Ins</p>
            </div>
        </div>
        <input
            value={text}
            onChange={v => setText(v.currentTarget.value)}
            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            id='search'
            placeholder='Type to search' />
        {(is_searching ? (search_result.items as AttendeeDropinModel[]) : items)
            .map(item => <AttendeeItem
                item={item}
                key={item.id}
                state={attendance?.state || AttendanceState.off}
                doDropin={(undo) => doDropin(item.id, undo)}
                loading={doing_dropin[item.id]}
            />)}
        <div className='py-4 flex justify-center'>
            {has_more ? <Button label="Load More" onClick={handleLoadMore} /> : null}
        </div>
        {search_result.loading || loading ? <Loading /> : null}
    </div>
}

export default AttendanceScreen
