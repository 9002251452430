import { padNumber } from "./pad-number";

export const getDuration = (time_in_seconds: number) => {
	const hours = Math.floor(time_in_seconds / 60 / 60);
	const minutes = Math.floor(time_in_seconds / 60 - hours * 60);
	const seconds = Math.floor(time_in_seconds - (minutes * 60) - (hours * 60 * 60));
	return [hours, minutes, seconds];
}

export const getDurationText = (time_in_seconds: number, seconds = false) => {
	const [h, m, s] = getDuration(time_in_seconds);
	if (seconds) {
		return `${h > 0 ? `${h}h ` : ''}${padNumber(m)}m ${padNumber(s)}s`
	}
	return `${h > 0 ? `${h}h ` : ''}${padNumber(m)}m`;
}