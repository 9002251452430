import React from 'react';
import { EventModel } from '../models/event.model';
import { getFormattedCurrency } from '../services/get-formatted-currency';
import { getTimeRangeTimezone } from '../services/time-range';
import LazyImage from './LazyImage';

export type EventHeaderProps = {
    event: EventModel;
}

function EventHeader({ event: item }: EventHeaderProps) {
    const feature_image = React.useMemo(() => {
        return item.feature_images && item.feature_images.length > 0
            ? item.feature_images[0] : undefined;
    }, [item.feature_images]);
    return (
        <div className='py-4'>
            <h2 className='text-xl font-bold'>{item.title}</h2>
            <p className='text-md text-gray-7000'>{getTimeRangeTimezone(item.starts_at, item.ends_at, item.timezone)}</p>
            <div className='grid gap-4 py-4 grid-cols-1 md:grid-cols-2 '>
                <div className='text-center'>
                    <p className='text-4xl font-bold'>{item.attendee_tally?.total_attendees || 0}</p>
                    <p className='uppercase text-sm text-gray-800'>Attendees</p>
                </div>
                <div className='text-center'>
                    <p className='text-4xl font-bold'>{getFormattedCurrency(item.invoice_item_tally?.total_amount || 0, item.organization.currency)}</p>
                    <p className='uppercase text-sm text-gray-800'>{item.invoice_item_tally?.total_quantity || 0} sales</p>
                </div>
            </div>
            <div className='w-full rounded-lg overflow-hidden'>
                {feature_image ? <div className=''>
                    <LazyImage
                        image_id={feature_image}
                        transformations={{
                            edits: {
                                rotate: null,
                                resize: {
                                    width: 1200,
                                }
                            }
                        }}
                    />
                </div> : null}
            </div>
        </div>
    )
}

export default EventHeader
