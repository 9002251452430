import { encode as base64_encode } from 'base-64';
import React, { DetailedHTMLProps, ImgHTMLAttributes, useMemo } from 'react';
import environment from '../environment';

const buildImgParam = (image_id: string, transformations = {}) => {
    return base64_encode(JSON.stringify({
        ...transformations,
        bucket: environment.image_bucket,
        key: image_id,
    }));
}

export const getImgUrl = (image_id: string, transformations = {}) => {
    return `${environment.cloudront_url}/${buildImgParam(image_id, transformations)}`
}

export interface LazyImageProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    image_id?: string;
    placeholder?: string;
    transformations?: any;
}
function LazyImage({
    image_id,
    placeholder,
    transformations,
    ...props
}: LazyImageProps) {

    const image_src = useMemo(() => !image_id ? placeholder : getImgUrl(image_id, transformations), [image_id, transformations]);

    if (!image_src) {
        return null;
    }

    return (
        <img
            {...props}
            src={image_src}
        />
    )
}

export default LazyImage