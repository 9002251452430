import React, { ReactNode } from "react";
import { useGetByPk } from "../apollo/networking/use-get-by-pk";
import LoadingScreen from "../components/LoadingScreen";
import { EventFragment, EventModel } from "../models/event.model";
import { useDialog } from "./dialog.context";

export type EventContextModel = {
    event_id: number;
    event: EventModel;
}

export const EventContext = React.createContext<EventContextModel>({} as any);

type Props = {
    event_id: number;
    children?: ReactNode; 
}

export const ProvideEvent = ({ event_id, children }: Props) => {
    const app_dialog = useDialog();
    const {
        entity,
        error,
        loading,
    } = useGetByPk<EventModel>({
        entity_name: 'event',
        fragment: EventFragment,
        pk_columns: [{
            name: 'id',
            value: event_id,
            type: 'bigint',
        }]
    });

    if (loading) {
        return <LoadingScreen />
    }

    if (!entity) {
        return <div className="container text-center py-8">
            <h1 className="text-4xl mb-2">Event not found.</h1>
            <p className="text-xl">Please verify your link.</p>
        </div>
    }

    return <EventContext.Provider value={{
        event_id,
        event: entity,
    }}>
        {children}
    </EventContext.Provider>
}

export const useEvent = (): EventContextModel => {
    return React.useContext(EventContext);
}