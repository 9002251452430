import React from 'react';
import { ProvideApollo } from './apollo/with-apollo';
import Main from './screens/Main';
import { ProvideDialog } from './services/dialog.context';

function App() {

  return (
    <ProvideDialog>
      <ProvideApollo>
        <Main />
      </ProvideApollo>
    </ProvideDialog>

  );
}

export default App;


