import moment from "moment"
import React from "react"
import Button from "../../components/Button"
import { AttendanceState } from "../../models/attendance.model"
import { getDurationText } from "../../services/duration"
import { AttendeeDropinModel } from "./use-list-attendees"

type Props = {
    item: AttendeeDropinModel;
    state: AttendanceState;
    doDropin: (undo?: boolean) => void;
    loading?: boolean;
}

export const AttendeeItem = ({
    item,
    state,
    doDropin,
    loading,
}: Props) => {
    const [dropin, duration_text] = React.useMemo(() => {
        const dropin = item.dropins && item.dropins.length > 0 ?
            item.dropins[0] : undefined;
        if (!!dropin?.checkout_at) {
            return [dropin,
                `${moment(dropin?.checkin_at).format('hh:mm a')} · ${getDurationText(moment(dropin.checkout_at).diff(dropin.checkin_at, 's'))}`
            ]
        }
        if (!!dropin?.checkin_at) {
            return [dropin, moment(dropin?.checkin_at).format('hh:mm a')]
        }
        return [dropin, 'Not Checked In']
    }, [item.dropins])

    const _doDropin = () => {
        if (state === AttendanceState.checkin && !!dropin?.checkin_at) {
            return doDropin(true);
        }
        if (state === AttendanceState.checkout && !!dropin?.checkout_at) {
            return doDropin(true);
        }
        return doDropin();
    }


    return <div className="p-2 flex items-center border-b-2">
        <div className="flex-1">
            <p className="text-lg">
                {item.name}
            </p>
            <p className="text-sm">{duration_text}</p>
        </div>
        {state !== AttendanceState.off ?
            state === AttendanceState.checkin ?
                <Button
                    loading={loading}
                    onClick={_doDropin}
                    label={!!dropin?.checkin_at ? 'Undo In' : 'Check In'}
                    className={!!dropin?.checkin_at ? 'bg-slate-500' : 'bg-green-500'}
                    small/>
                : <Button
                    loading={loading}
                    onClick={_doDropin}
                    label={!!dropin?.checkout_at ? 'Undo Out' : 'Check Out'}
                    small />
                    // colorScheme={!!dropin?.checkout_at ? 'danger' : 'primary'}
            : null}
    </div>
}