import { Currency } from "../models/currency";

export function getFormattedCurrency(value: number, currency?: Currency, display_symbol = 'symbol') {
  if (!currency) {
    return `${new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value / 100)}`;
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: display_symbol,
    currency: currency.toUpperCase(),
  }).format(value / 100)
}