

export enum AttendanceState {
    off=0,
    checkin=1,
    checkout=2,
}

export const AttendanceStateOptions = [{
    id: AttendanceState.checkin,
    label: 'Check In',
},{
    id: AttendanceState.checkout,
    label: 'Check Out',
},{
    id: AttendanceState.off,
    label: 'Lock',
},]

export type AttendanceModel = {
    id: number;
    event_id: number;
    created_at: string;
    name: string;
    state: AttendanceState;
    checkin_tally?: {
        total_checkins?: number;
        id: number;
    }
}

export const AttendanceFragment = `
    id
    event_id
    created_at
    name
    state
    checkin_tally {
        total_checkins
        id
    }
`;
