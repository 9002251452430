import { useSubscribeByPk } from "../../apollo/networking/use-subscribe-by-pk";
import { useUpdateByPk } from "../../apollo/networking/use-update-by-pk"
import { AttendanceFragment, AttendanceModel, AttendanceState } from "../../models/attendance.model"

export const useUpdateAttendance = (attendance_id: number) => {
    const {
        entity,
        loading,
     } = useSubscribeByPk<AttendanceModel>({
        entity_name: 'attendance',
        pk_columns: [{
            name: 'id',
            value: attendance_id,
            type: 'bigint',
        }],
        fragment: AttendanceFragment,
    });

    const { 
        onUpdate,
    } = useUpdateByPk({
        entity_name: 'attendance',
        pk_columns: [{
            name: 'id',
            value: attendance_id,
            type: 'bigint'
        }],
        fragment: AttendanceFragment
    });

    const updateState = async (state: AttendanceState) => {
        await onUpdate({
            state,
        })
    }

    return {
        attendance: entity,
        loading,
        updateState,
    }
}