export type AttendeeModel = {
    id: number;
    created_at: string;
    updated_at: string;
    ticket_id: number;
    name: string;
    invoice_item_id?: number;
    email: string;
    agd_id?: string;
    data?: any;
    deleted_at?: string;
    ticket: {
        id: number;
        name: string;
        schema?: any;
    }
}

export const AttendeeFragment = `
    id
    created_at
    updated_at
    ticket_id
    name
    invoice_item_id
    email
    agd_id
    data
    deleted_at
    ticket {
        id
        name
        schema
    }
`;