import gql from "graphql-tag";
import React from "react";
import { ApolloQueryResult, useQuery } from "@apollo/client";

const QUERY_LIST = (props: {
	entity_name: string;
	clause?: string;
	query?: string;
	fragment: string;
}) => gql`
query query_${props.entity_name}${props.clause ? `(${props.clause})` : ''} {
	${props.entity_name}${props.query ? `(${props.query})` : ''} {
		${props.fragment}
	}
}
`;

export type UseListProps = {
	entity_name: string;
	clause_outer?: string;
	query_inner?: string;
	variables?: any;
	fragment: string;
}

export type ListResponse<T> = {
	loading?: boolean;
	error?: any;
	data: any;
	refetch: () => Promise<ApolloQueryResult<any>>;
	items: T[];
}

export const useList = <T extends any>({
	...props
}: UseListProps): ListResponse<T> => {
	const {
		entity_name,
		clause_outer: clause,
		query_inner: query,
		variables,
		fragment,
	} = props;
	const document_node = React.useMemo(() => {
		return QUERY_LIST({
			entity_name,
			clause,
			query,
			fragment,
		});
	}, [clause, query])
	const { data, loading, error, refetch } = useQuery(document_node, {
		variables,
	});

	const onRefetch = () => {
		return refetch(variables)
	}

	const items = data ? (data[entity_name] || []) as T[] : [];
	return {
		loading,
		error,
		data,
		items,
		refetch: onRefetch,
	}
}