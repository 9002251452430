import moment from 'moment-timezone'
import { padNumber } from './pad-number';

export const getTimeRangeTimezone = (starts_at?: string, ends_at?: string, timezone?: string) => {
	if (!starts_at && !ends_at) {
		return ''
	}
	if (!starts_at) {
		return `Ends at: ${getTimeWTimezone(ends_at as string, timezone)}`;
	}

	if (!ends_at) {
		return `Starts at: ${getTimeWTimezone(starts_at as string, timezone)}`;
	}

	const tz = timezone || moment.tz.guess();
	const start = moment(starts_at).tz(tz);
	const end = moment(ends_at).tz(tz);

	if (start.clone().startOf('day').isSame(end.clone().startOf('day'))) {
		return `${start.format('LLL')} - ${end.format('hh:mm A')} (${moment.tz(tz).zoneAbbr()})`
	}
	return `${start.format('LLL')} - ${end.format('LLL')} (${moment.tz(tz).zoneAbbr()})`
}


export const getTimeWTimezone = (starts_at: string, timezone?: string) => {
	const tz = timezone || moment.tz.guess();
	const start = moment(starts_at).tz(tz);

	return `${start.format('LLL')} (${moment.tz(tz).zoneAbbr()})`
}

export const getTimeRange = (starts_at?: string, ends_at?: string) => {
	if (!starts_at) {
		return '';
	}
	const start = moment(starts_at);
	if (!ends_at) {
		return start.format('LLL');
	}
	const end = moment(ends_at);
	if (start.clone().startOf('day').isSame(end.clone().startOf('day'))) {
		return `${start.format('LLL')} - ${end.format('hh:mm A')}`
	}
	return `${start.format('LLL')} - ${end.format('LLL')}`
}

export const getDuration = (time_in_seconds: number) => {
	const hours = Math.floor(time_in_seconds / 60 / 60);
	const minutes = Math.floor(time_in_seconds / 60 - hours * 60);
	const seconds = Math.floor(time_in_seconds - (minutes * 60) - (hours * 60 * 60));
	return [hours, minutes, seconds];
}

export const getDurationText = (time_in_seconds: number, seconds = false) => {
	const [h, m, s] = getDuration(time_in_seconds);
	if (seconds) {
		return `${h > 0 ? `${h}h ` : ''}${padNumber(m)}m ${padNumber(s)}s`
	}
	return `${h > 0 ? `${h}h ` : ''}${padNumber(m)}m`;
}

export const getTime = (time_a: string, time_b: string, type: 'duration' | 'to' = 'duration') => {
	if (!time_b) {
		return moment(time_a).format('LLL');
	}

	if (type === 'duration') {
		const [hours, minutes] = getDuration(
			(new Date(time_b).getTime() - new Date(time_a).getTime()) / 1000);
		const time = moment(time_a).format('LLL');
		return `${time} · ${hours}h ${minutes}m`
	}

	return getTimeRange(
		time_a, time_b,
	);
};

