import { useKeysetPagination } from "../../apollo/networking/use-keyset-pagination";
import { AttendeeModel, AttendeeFragment } from "../../models/attendee.model";

export interface AttendeeDropinModel extends AttendeeModel {
    dropins: {
        id: number;
        checkin_at: string;
        checkout_at?: string;
    }[];
}

export const AttendeeDropinFragment = `
    ${AttendeeFragment}
    dropins(where: {attendance_id: {_eq: $attendance_id}}) {
        id
        checkin_at
        checkout_at
    }
`;

export const useListAttendees = (event_id: number, attendance_id: number, cursor = 0) => {
    const result = useKeysetPagination<AttendeeDropinModel>({
        entity_name: 'attendee',
        fragment: AttendeeDropinFragment,
        query_inner: `where: {
            _and: [
                {id: {_gt: $cursor}}
                {ticket: {
                    event_id: {_eq: $event_id}
                }}
            ],
       }, order_by: {id: asc}`,
        clause_outer: `$event_id: bigint!, $attendance_id: bigint!, $cursor: bigint!`,
        variables: {
            event_id,
            attendance_id,
            cursor,
        }
    })

    return result;
}