export const ProdConfig = {
  production: true,
  backend_url: 'https://certifysimple-graphql.herokuapp.com/v1/graphql',
  backend_ws_url: 'wss://certifysimple-graphql.herokuapp.com/v1/graphql',
  frontend_url: 'https://zensteri.web.app',
  cloudront_url: 'https://d1niz8ad8nu5h5.cloudfront.net',
  image_bucket: 'edropin-images-1',
  default_page_size: 50,
  firebaseConfig: {
    apiKey: "AIzaSyDwx4klV719KaxtNW5-_kWBrdLiF_K3tus",
    authDomain: "certify-simple.firebaseapp.com",
    projectId: "certify-simple",
    storageBucket: "certify-simple.appspot.com",
    messagingSenderId: "1016111139978",
    appId: "1:1016111139978:web:d0995ecf71c7e63be33173",
    measurementId: "G-ZHX91R4W3C"
  }
}